.download {
  padding: 16vw 5.33333vw 2.66667vw;
  text-align: center;
  box-sizing: border-box; }
  .download h2 {
    font-family: PingFangSC-Semibold;
    font-size: 8.8vw;
    color: #1777f1;
    font-weight: bold;
    line-height: 1.2; }
  .download p {
    font-family: PingFangSC-Regular; }
  .download h3 {
    font-family: PingFangSC-Semibold;
    font-size: 4.8vw;
    color: #333;
    font-weight: bold; }

.summary {
  font-size: 5.6vw;
  color: #1777f1; }

.animate {
  width: 26.66667vw;
  height: 26.66667vw;
  position: relative;
  margin: 18.66667vw auto 18.66667vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%; }
  .animate:after {
    display: block;
    content: "";
    width: 140%;
    height: 140%;
    border-radius: 50%;
    background: url("/download/circle.png") no-repeat center center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0);
    background-size: 96%;
    animation-name: makeRotate;
    animation-duration: 3s;
    animation-delay: 1s; }

@keyframes makeRotate {
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

.link {
  color: #2470ec;
  text-decoration: underline;
  padding: 0 1.33333vw; }

.download_wrapper {
  margin-top: 10.66667vw;
  margin-bottom: 16vw; }
  .download_wrapper > p {
    margin: 2.66667vw; }
  .download_wrapper .box {
    justify-content: space-around; }
    .download_wrapper .box .download_btn_ios {
      display: block;
      background: #1777f1;
      line-height: 13.33333vw;
      color: #fff;
      font-size: 3.73333vw;
      white-space: nowrap;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.25s;
      border-radius: 1.06667vw;
      line-height: 1.5;
      padding: 4vw 8vw;
      position: relative;
      overflow: hidden;
      text-decoration: none; }
      .download_wrapper .box .download_btn_ios :global(.label) {
        background-image: url(/download/label.png);
        background-size: 100% auto;
        width: 19.2vw;
        height: 14.4vw;
        position: absolute;
        top: 0;
        right: 0; }
      .download_wrapper .box .download_btn_ios .ios_icon {
        display: inline-block;
        background: url("/download/ios.svg") center no-repeat;
        width: 5.33333vw;
        height: 5.33333vw;
        background-size: 100% 100%;
        margin-right: 1.06667vw; }
      .download_wrapper .box .download_btn_ios.loading {
        opacity: 0.8;
        transition: 0.25s; }
        .download_wrapper .box .download_btn_ios.loading span {
          display: block;
          visibility: visible;
          opacity: 1;
          animation: loadingState 1.2s infinite;
          -webkit-animation: loadingState 1.2s infinite; }
    .download_wrapper .box .download_btn_appStore {
      background: #000; }
  .download_wrapper .download_btn_page {
    display: block;
    background: transparent;
    color: #1779ff;
    font-family: PingFangSC-Regular;
    text-decoration: underline;
    padding: 4vw; }
    .download_wrapper .download_btn_page :global(.gestrue_icon) {
      display: inline-block;
      vertical-align: text-top;
      background: url(/download/gestrue_icon.png) no-repeat center center;
      background-size: contain;
      width: 5.06667vw;
      height: 4vw;
      margin-left: 1.33333vw; }
  .download_wrapper .download_btn_and {
    display: block;
    background: #1777f1;
    line-height: 13.33333vw;
    color: #fff;
    font-size: 3.73333vw;
    white-space: nowrap;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s;
    border-radius: 1.06667vw;
    line-height: 1.5;
    padding: 4vw 2.66667vw;
    position: relative;
    overflow: hidden; }
    .download_wrapper .download_btn_and :global(.label) {
      background-image: url(/download/label.png);
      background-size: 100% auto;
      width: 19.2vw;
      height: 14.4vw;
      position: absolute;
      top: 0;
      right: 0; }
    .download_wrapper .download_btn_and.loading {
      opacity: 0.8;
      transition: 0.25s; }
      .download_wrapper .download_btn_and.loading span {
        display: block;
        visibility: visible;
        opacity: 1;
        animation: loadingState 1.2s infinite;
        -webkit-animation: loadingState 1.2s infinite; }

@keyframes loadingState {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes loadingState {
  100% {
    transform: rotate(360deg); } }

.strength {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.73333vw;
  color: #8f8f8f; }
  .strength span {
    display: inline-block;
    padding: 0 2.66667vw;
    border-right: 1px solid #d8d8d8;
    line-height: 1; }
    .strength span:last-child {
      border-right: none; }

.shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 111;
  background-color: rgba(0, 0, 0, 0.8);
  background-position: right 2.66667vw top 2.66667vw;
  background-repeat: no-repeat;
  background-size: 50%; }

.shadow {
  background-image: url("/tips.png"); }
  .shadow.android {
    background-image: url("/tips_android.png"); }
