@charset "UTF-8";
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 3.2vw;
  line-height: 1.875;
  color: #333;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: auto;
  overflow-x: hidden; }

.tips {
  color: #c5c5c5; }

.full-screen {
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  box-sizing: border-box; }

.icon-404 {
  display: block;
  margin: 0 auto 13.33333vw;
  width: 37.33333vw;
  height: 37.33333vw;
  background: url("/404.png") no-repeat center center;
  background-size: contain; }

/*----- flex 容器 ----*/
.flex-container {
  display: flex; }

.flex-space-between {
  justify-content: space-between; }

.flex-space-around {
  justify-content: space-around; }

.flex-x-center {
  justify-content: center; }

.flex-y-start {
  align-items: flex-start; }

.flex-x-end {
  justify-content: flex-end; }

.flex-y-end {
  align-items: flex-end; }

.flex-y-center {
  align-items: center; }

.flex-center {
  align-items: center;
  justify-content: center; }

.layout-vertical {
  flex-direction: column; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-block {
  flex-grow: 1; }

.flex-shrink {
  flex-shrink: 1; }

.flex-fixed {
  flex-shrink: 0;
  flex-grow: 0; }

.plane {
  padding: 6.4vw 4.26667vw;
  background: #fff; }

.full-screen {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box; }
